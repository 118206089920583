<template>
  <section>
    <table-header
      v-bind="{
        dateable: false,
        filterable: false,
        createTitle: !hasPermission ? 'Добавить источник' : '',
        currentPage: page,
      }"
      @update:page-size="onPerPageChange"
      @update:page="onPageChange"
      @update="onUpdateFilter"
      @reset="onResetFilter"
      @create="onCreate"
    >
      <template #actions-left>
        <b-button
          class="mb-1 mr-0 mb-md-0 mr-md-1"
          v-bind="{
            disabled: !selected.length,
            variant: 'success'
          }"
          @click="setStatus(1)"
        >
          <span> Активировать </span>
        </b-button>
        <b-button
          v-bind="{
            disabled: !selected.length,
            variant: 'danger'
          }"
          @click="setStatus(0)"
        >
          <span> Деактивировать </span>
        </b-button>
      </template>

      <template #actions-right>
        <b-form-select
          v-model="status"
          :clearable="false"
          :options="statusCollection"
          size="md"
          @input="loadCollection"
        />
      </template>
    </table-header>

    <table-body
      v-bind="{
        currentPage: page,
        pageCount,
        pageSize: page_size,
        itemCount
      }"
      @update:page-size="onPerPageChange"
      @update:page="onPageChange"
    >
      <b-table
        class="position-relative"
        v-bind="{
          items: sources,
          fields,
          striped,
          hover,
          responsive,
          showEmpty: true,
          emptyText: 'Нет данных',
          perPage: page_size,
          busy: loading,
        }"
        @sort-changed="onSort"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <span>Загрузка...</span>
          </div>
        </template>
        <template #head(select)>
          <b-form-checkbox
            v-bind="{
              checked: sources && selected.length === sources.length
            }"
            @change="onSelectAll()"
          ></b-form-checkbox>
        </template>

        <template #cell(select)="{ item }">
          <b-form-checkbox
            :checked="selected.includes(item.id)"
            @change="onSelectRow({ ...item })"
          />
        </template>

        <template #cell(color)="{ item }">
          <b-button
            v-bind:style=" { backgroundColor: item.color }"
            v-bind="{
                  block: true,
                  variant: item.color
                }"
          >
          </b-button>
        </template>

        <template #cell(status)="{ item }">
          <b-button
            v-bind:style="{
              width: '175px'
            }"
            v-bind="{
              variant:  item.status ? 'success' : 'danger'
            }"
          >
            {{ item.status ? 'Активен' : 'Не активен' }}
          </b-button>
        </template>

        <template #cell(actions)="{ item }">
          <table-buttons
            v-bind="{
              disabled: hasPermission
            }"
            @edit="onEdit({ ...item })"
            @delete="onDelete({ ...item })"
          ></table-buttons>
        </template>
      </b-table>
    </table-body>

    <validation-observer v-slot="{ invalid }">
      <common-dialog
        v-bind="{
          title: modalConfig.title,
          value: modalConfig.value,
          okTitle: modalConfig.okTitle,
          okDisabled: invalid
        }"
        @confirm="onConfirm"
      >
        <template v-if="modal.delete.visible">
          <p> {{ `${modal.delete.content} ${form.name}?`}} </p>
        </template>
        <template v-if="modal.edit.visible || modal.create.visible">
          <validation-provider v-bind="{
              rules: {
                required: true,
              }
            }">
            <b-form-group
              slot-scope="{ valid, errors }"
              v-bind="{
                  label:  'Название источника'
                }"
            >
              <b-input
                v-model.trim="form.name"
                v-bind="{
                  state: errors[0] ? false : (valid ? true : null)
                }"
              />
            </b-form-group>
          </validation-provider>

          <section class="position-relative w-100 h-100">
            <template v-if="showPicker">
              <color-picker
                v-model.trim="form.color"
                v-click-outside="{
                  exclude: ['color-picker-button'],
                  handler: 'hideColorPicker',
                }"
              />
            </template>
            <validation-provider v-bind="{
              rules: {
                required: true,
                regex: /^#[0-9A-F]{6}$/
              }
            }">
              <b-form-group
                slot-scope="{ valid, errors }"
                v-bind="{
                  label:  'Цвет источника'
                }"
              >
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-button
                      ref="color-picker-button"
                      v-bind:style=" { backgroundColor: form.color.hex }"
                      v-bind="{
                         variant: form.color.hex
                      }"
                      @click="showPicker = !showPicker"
                    >
                    </b-button>
                  </b-input-group-prepend>
                  <b-input
                    :value="form.color.hex"
                    @input="onColorInput"
                    v-bind="{
                      state: errors[0] ? false : (valid ? true : null)
                    }"
                  />
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </section>

          <validation-provider v-bind="{
              rules: {
                required: true,
                numeric: true
              }
            }">
            <b-form-group
              slot-scope="{ valid, errors }"
              v-bind="{
                  label:  'Порядок сортировки'
                }"
            >
              <b-input
                v-model.trim="form.sort"
                v-bind="{
                  state: errors[0] ? false : (valid ? true : null)
                }"
              />
            </b-form-group>
          </validation-provider>

          <b-form-group v-bind="{
            label: 'Статус'
          }">
            <b-select
              v-model="form.status"
              v-bind="{
                options: statusCollection.slice(1)
              }"
            />
          </b-form-group>

        </template>
      </common-dialog>
    </validation-observer>
  </section>
</template>

<script>
import { AuthNameSpace, RoleCollection } from '@/store/modules/auth/types'
import { mapState, mapActions } from 'vuex'
import { SourceNameSpace, SourceActionTypes } from '@/store/modules/source/types'
import { throttle } from 'lodash'
import { duration } from '@/config'
import { BTable } from 'bootstrap-vue'

import { Chrome as ColorPicker } from 'vue-color'

import TableHeader from '@/components/common/table/TableHeader'
import TableBody from '@/components/common/table/TableBody'
import TableButtons from '@/components/common/buttons/TableButtons'

import { columns, modal } from '@/config/source'
import { statusCollection } from '@/config/shared'

import clickOutside from '@/directive/click-outside'

export default {
  name: 'SourcesOfOrders',
  components: {
    BTable,
    TableHeader,
    TableBody,
    TableButtons,
    ColorPicker,
    CommonDialog: () => import('@/components/common/common-dialog.vue'),
  },
  directives: {
    clickOutside,
  },
  data() {
    return {
      status: '',
      color: '',
      page_size: 10,
      page: 1,
      showPicker: false,
      modal,
      selected: [],
      form: {
        name: '',
        status: 1,
        sort: '',
        color: {
          hex: '',
        },
      },
      sort_by: {
        sortBy: 'sort',
        sortDesc: 'asc',
      },
      loading: false,
    }
  },
  props: {
    striped: {
      type: Boolean,
      default() {
        return true
      },
    },
    hover: {
      type: Boolean,
      default() {
        return true
      },
    },
    responsive: {
      type: Boolean,
      default() {
        return true
      },
    },
    emptyText: {
      type: String,
      default() {
        return 'Нет данных'
      },
    },
    showEmpty: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  computed: {
    ...mapState(SourceNameSpace, {
      sources: 'sources',
      source: 'source',
      pageCount: 'pageCount',
      itemCount: 'itemCount',
    }),
    ...mapState(AuthNameSpace, {
      userData: 'userData',
    }),
    statusCollection() {
      return statusCollection
    },
    fields() {
      return columns
    },
    modalConfig() {
      return {
        title: modal.create.visible ? modal.create.title : modal.delete.visible ? modal.delete.title : modal.edit.visible ? modal.edit.title : modal.image.visible ? modal.image.title : modal.status.title,
        value: modal.create.visible || modal.edit.visible || modal.delete.visible || modal.image.visible || modal.image.status,
        okTitle: modal.create.visible ? modal.create.okTitle : modal.delete.visible ? modal.delete.okTitle : modal.edit.visible ? modal.edit.okTitle : modal.image.visible ? modal.image.okTitle : modal.status.okTitle,
      }
    },
    hasPermission() {
      return this.userData.role === RoleCollection.florist || this.userData.role === RoleCollection.courier
    },
  },

  async beforeMount() {
    await this.loadCollection()
  },
  methods: {
    ...mapActions(SourceNameSpace, {
      [SourceActionTypes.LoadSourceCollection]: SourceActionTypes.LoadSourceCollection,
      [SourceActionTypes.CreateSource]: SourceActionTypes.CreateSource,
      [SourceActionTypes.UpdateSource]: SourceActionTypes.UpdateSource,
      [SourceActionTypes.DeleteSource]: SourceActionTypes.DeleteSource,
      [SourceActionTypes.SetStatusToSource]: SourceActionTypes.SetStatusToSource,
      [SourceActionTypes.LoadSource]: SourceActionTypes.LoadSource,
    }),

    loadCollection: throttle(async function() {
      try {
        this.loading = true
        await this[SourceActionTypes.LoadSourceCollection]({ ...this.$data })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }, duration),

    load: throttle(async function({ id }) {
      await this[SourceActionTypes.LoadSource]({ id })
    }, duration),

    onSelectRow({ id }) {
            this.selected.find(sources => sources === id) ? this.selected.splice(this.selected.indexOf(id), 1) : this.selected.push(id)
    },

    onSelectAll() {
            this.sources.length === this.selected.length ? (this.selected = []) : (this.selected = [...this.sources.map(({ id }) => id)])
    },

    async setStatus(status) {
      await this[SourceActionTypes.SetStatusToSource]({ sources: this.selected.map(id => ({ id, status })) })
      await this.loadCollection()
      this.selected = []
    },

    onCreate() {
      for (const key in this.form) {
        if (this.form[key]) {
                    key === 'status' ? (this.form[key] = 1) : (this.form[key] = '')
        }
      }

      this.form.color = { hex: '' }

      this.modal.create.visible = true
    },

    async onEdit({ id }) {
      await this[SourceActionTypes.LoadSource]({ id })

      const { name, status, sort, color } = this.source

      this.form = {
        id,
        name,
        status,
        sort,
        color: {
          hex: color,
        },
      }

      this.modal.edit.visible = true
    },

    onDelete({ id, name }) {
      this.form.name = name
      this.form.id = id

      this.modal.delete.visible = true
    },

    async onConfirm({ confirmed }) {
      if (confirmed) {
        try {
          if (this.modal.edit.visible) {
            await this[SourceActionTypes.UpdateSource]({ ...this.form, color: this.form.color.hex })
            await this.loadCollection()
          }

          if (this.modal.create.visible) {
            await this[SourceActionTypes.CreateSource]({ ...this.form, color: this.form.color.hex })
            await this.loadCollection()
          }

          if (this.modal.delete.visible) {
            await this[SourceActionTypes.DeleteSource]({ ...this.form })
            if (this.sources.length === 1 && this.page !== 1) this.page -= 1
            await this.loadCollection()
          }
        } catch {
          for (const type in this.modal) {
            if (this.modal[type].visible) {
              this.modal[type].visible = false
            }
          }
        }
      }

      for (const type in this.modal) {
        if (this.modal[type].visible) {
          this.modal[type].visible = false
        }
      }

      this.showPicker = false
    },

    async onPerPageChange(count) {
      this.page = 1
      this.page_size = parseInt(count, 10)

      await this.loadCollection()
    },

    async onPageChange(count) {
      this.page = parseInt(count, 10)

      await this.loadCollection()
    },

    async onUpdateFilter(context) {
      Object.assign(this.$data, context)
      this.page = 1
      await this.loadCollection(context)
    },

    async onResetFilter(context) {
      Object.assign(this.$data, context)
      this.status = null

      await this.loadCollection()
    },

    onResetForm() {
      for (const key in this.form) {
        if (this.form.hasOwnProperty(key)) {
          this.form[key] = ''
        }
      }
      this.showPicker = false
    },

    hideColorPicker() {
      this.showPicker = false
    },

    async onSort({ sortBy, sortDesc }) {
      if (sortBy) {
        if (sortBy !== this.sort_by.sortBy) {
          this.sort_by.sortBy = sortBy
        }
        this.sort_by.sortDesc = sortDesc ? 'desc' : 'asc'
      }

      await this.loadCollection()
    },
    onColorInput(value) {
      this.form.color.hex = value
    },
  },
}
</script>

<style lang="scss" scoped>
.vc-chrome {
    position: absolute;
    z-index: 1000;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}
</style>
