var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('table-header',_vm._b({on:{"update:page-size":_vm.onPerPageChange,"update:page":_vm.onPageChange,"update":_vm.onUpdateFilter,"reset":_vm.onResetFilter,"create":_vm.onCreate},scopedSlots:_vm._u([{key:"actions-left",fn:function(){return [_c('b-button',_vm._b({staticClass:"mb-1 mr-0 mb-md-0 mr-md-1",on:{"click":function($event){return _vm.setStatus(1)}}},'b-button',{
          disabled: !_vm.selected.length,
          variant: 'success'
        },false),[_c('span',[_vm._v(" Активировать ")])]),_c('b-button',_vm._b({on:{"click":function($event){return _vm.setStatus(0)}}},'b-button',{
          disabled: !_vm.selected.length,
          variant: 'danger'
        },false),[_c('span',[_vm._v(" Деактивировать ")])])]},proxy:true},{key:"actions-right",fn:function(){return [_c('b-form-select',{attrs:{"clearable":false,"options":_vm.statusCollection,"size":"md"},on:{"input":_vm.loadCollection},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})]},proxy:true}])},'table-header',{
      dateable: false,
      filterable: false,
      createTitle: !_vm.hasPermission ? 'Добавить источник' : '',
      currentPage: _vm.page,
    },false)),_c('table-body',_vm._b({on:{"update:page-size":_vm.onPerPageChange,"update:page":_vm.onPageChange}},'table-body',{
      currentPage: _vm.page,
      pageCount: _vm.pageCount,
      pageSize: _vm.page_size,
      itemCount: _vm.itemCount
    },false),[_c('b-table',_vm._b({staticClass:"position-relative",on:{"sort-changed":_vm.onSort},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('span',[_vm._v("Загрузка...")])],1)]},proxy:true},{key:"head(select)",fn:function(){return [_c('b-form-checkbox',_vm._b({on:{"change":function($event){return _vm.onSelectAll()}}},'b-form-checkbox',{
            checked: _vm.sources && _vm.selected.length === _vm.sources.length
          },false))]},proxy:true},{key:"cell(select)",fn:function(ref){
          var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"checked":_vm.selected.includes(item.id)},on:{"change":function($event){return _vm.onSelectRow(Object.assign({}, item))}}})]}},{key:"cell(color)",fn:function(ref){
          var item = ref.item;
return [_c('b-button',_vm._b({style:({ backgroundColor: item.color })},'b-button',{
                block: true,
                variant: item.color
              },false))]}},{key:"cell(status)",fn:function(ref){
              var item = ref.item;
return [_c('b-button',_vm._b({style:({
            width: '175px'
          })},'b-button',{
            variant:  item.status ? 'success' : 'danger'
          },false),[_vm._v(" "+_vm._s(item.status ? 'Активен' : 'Не активен')+" ")])]}},{key:"cell(actions)",fn:function(ref){
          var item = ref.item;
return [_c('table-buttons',_vm._b({on:{"edit":function($event){return _vm.onEdit(Object.assign({}, item))},"delete":function($event){return _vm.onDelete(Object.assign({}, item))}}},'table-buttons',{
            disabled: _vm.hasPermission
          },false))]}}])},'b-table',{
        items: _vm.sources,
        fields: _vm.fields,
        striped: _vm.striped,
        hover: _vm.hover,
        responsive: _vm.responsive,
        showEmpty: true,
        emptyText: 'Нет данных',
        perPage: _vm.page_size,
        busy: _vm.loading,
      },false))],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('common-dialog',_vm._b({on:{"confirm":_vm.onConfirm}},'common-dialog',{
        title: _vm.modalConfig.title,
        value: _vm.modalConfig.value,
        okTitle: _vm.modalConfig.okTitle,
        okDisabled: invalid
      },false),[(_vm.modal.delete.visible)?[_c('p',[_vm._v(" "+_vm._s(((_vm.modal.delete.content) + " " + (_vm.form.name) + "?"))+" ")])]:_vm._e(),(_vm.modal.edit.visible || _vm.modal.create.visible)?[_c('validation-provider',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var valid = ref.valid;
      var errors = ref.errors;
return _c('b-form-group',_vm._b({},'b-form-group',{
                label:  'Название источника'
              },false),[_c('b-input',_vm._b({model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}},'b-input',{
                state: errors[0] ? false : (valid ? true : null)
              },false))],1)}}],null,true)},'validation-provider',{
            rules: {
              required: true,
            }
          },false)),_c('section',{staticClass:"position-relative w-100 h-100"},[(_vm.showPicker)?[_c('color-picker',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
                exclude: ['color-picker-button'],
                handler: 'hideColorPicker',
              }),expression:"{\n                exclude: ['color-picker-button'],\n                handler: 'hideColorPicker',\n              }"}],model:{value:(_vm.form.color),callback:function ($$v) {_vm.$set(_vm.form, "color", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.color"}})]:_vm._e(),_c('validation-provider',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var valid = ref.valid;
              var errors = ref.errors;
return _c('b-form-group',_vm._b({},'b-form-group',{
                label:  'Цвет источника'
              },false),[_c('b-input-group',{staticClass:"mb-2"},[_c('b-input-group-prepend',[_c('b-button',_vm._b({ref:"color-picker-button",style:({ backgroundColor: _vm.form.color.hex }),on:{"click":function($event){_vm.showPicker = !_vm.showPicker}}},'b-button',{
                       variant: _vm.form.color.hex
                    },false))],1),_c('b-input',_vm._b({attrs:{"value":_vm.form.color.hex},on:{"input":_vm.onColorInput}},'b-input',{
                    state: errors[0] ? false : (valid ? true : null)
                  },false))],1)],1)}}],null,true)},'validation-provider',{
            rules: {
              required: true,
              regex: /^#[0-9A-F]{6}$/
            }
          },false))],2),_c('validation-provider',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var valid = ref.valid;
          var errors = ref.errors;
return _c('b-form-group',_vm._b({},'b-form-group',{
                label:  'Порядок сортировки'
              },false),[_c('b-input',_vm._b({model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.sort"}},'b-input',{
                state: errors[0] ? false : (valid ? true : null)
              },false))],1)}}],null,true)},'validation-provider',{
            rules: {
              required: true,
              numeric: true
            }
          },false)),_c('b-form-group',_vm._b({},'b-form-group',{
          label: 'Статус'
        },false),[_c('b-select',_vm._b({model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},'b-select',{
              options: _vm.statusCollection.slice(1)
            },false))],1)]:_vm._e()],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }